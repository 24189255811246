<template>
	<div class="col-12">
		<div class="card">
			<h5>Produtos Disponíveis</h5>
			<DataTable :value="produtosDisponiveisList" :paginator="true" class="p-datatable-gridlines" :rows="10"
				dataKey="id" :rowHover="true" responsiveLayout="stack" :loading="loading">
				<template #header>
					<div class="flex flex-column sm:flex-row">
						<Dropdown class="mb-2" placeholder="Família" id="familia" v-model="familiaSelecionada"
							:options="familiaProdutosDisponiveisList" optionLabel="codNomemFamilia" @change="filtrar"
							style="width: 15rem;" />

						<!-- <Button label="Consultar" 
								@click="filtrar" 
								class="mb-2 ml-3" 
								icon="pi pi-search"></Button> -->
						<Button type="button" icon="pi pi-filter-slash" label="Limpar" class="p-button-outlined mb-2 ml-3"
							@click="clearFilter()" />
						<Button label="Exportar" icon="pi pi-file-excel" class="p-button-warning mb-2 ml-3" @click="exportar"
							:disabled="!exportarXls" />
					</div>
				</template>
				<template #empty>
					Nenhum registro encontrado.
				</template>
				<template #loading>
					Carregando... Por favor, aguarde.
				</template>
				<Column field="codEstabelecimento" header="Est." :sortable="true" style="text-align: center;">
					<template #body="data">
						{{ data.data.codEstabelecimento }}
					</template>
				</Column>
				<Column field="codFamilia" header="CodFamilia" :sortable="true" style="display: none;">
					<template #body="data">
						{{ data.data.codFamilia }}
					</template>
				</Column>
				<Column field="codNomeFamilia" header="Família" :sortable="true" style="text-align: center; min-width:10rem">
					<template #body="data">
						{{ data.data.codNomeFamilia }}
					</template>
				</Column>
				<Column field="codItem" header="Cod, Item" :sortable="true" style="text-align: center; min-width:10rem">
					<template #body="data">
						{{ data.data.codItem }}
					</template>
				</Column>
				<Column field="descricaoItem" header="Item" :sortable="true" style="text-align: center; min-width:10rem">
					<template #body="data">
						{{ data.data.descricaoItem }}
					</template>
				</Column>
				<Column field="numeroNotaFiscal" header="Nota Fiscal" :sortable="true"
					style="text-align: center; min-width:10rem">
					<template #body="data">
						{{ data.data.numeroNotaFiscal }}
					</template>
				</Column>
				<Column field="dataEmissaoNotaFiscal" header="Data NF" :sortable="true"
					style="text-align: center; min-width:12rem">
					<template #body="data">
						{{ formatDate(data.data.dataEmissaoNotaFiscal) }}
					</template>
				</Column>
				<Column field="codNomeImovel" header="Imóvel" :sortable="true" style="text-align: center; min-width:12rem">
					<template #body="data">
						{{ data.data.codNomeImovel }}
					</template>
				</Column>
				<Column field="qtdSaldo" header="Qtd" :sortable="true" style="text-align: center; min-width:5rem">
					<template #body="data">
						{{ data.data.qtdSaldo }}
					</template>
				</Column>
				<Column field="unidade" header="Unidade" :sortable="true" style="text-align: center; min-width:5rem">
					<template #body="data">
						{{ data.data.unidade }}
					</template>
				</Column>
				<Column field="valorUnitario" header="Valor Unitário (R$)" :sortable="true"
					style="text-align: center; min-width:12rem">
					<template #body="data">
						{{ formatCurrency(data.data.valorUnitario) }}
					</template>
				</Column>
				<Column field="valorTotal" header="Valor Total (R$)" :sortable="true"
					style="text-align: center; min-width:12rem">
					<template #body="data">
						{{ formatCurrency(data.data.valorTotal) }}
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
</template>

<script>
import Formatacao from '../../utilities/Formatacao';
//import ExportarPlanilha from '../../utilities/ExportarPlanilha';
import ProdutoDisponivelService from '../../service/ProdutosDisponiveis';
var XLSX = require("xlsx");
export default {
	name: 'ProdutosDisponiveis',

	props: {
		matricula: {
			type: Number,
			required: true
		},
	},

	data() {
		return {
			produtosDisponiveisList: [],
			familiaProdutosDisponiveisList: [],
			familia: "*",
			familiaSelecionada: null,
			familia: [],
			exportarXls: false,
			dataSource: null,
			filters1: null,
			loading: false,
		};
	},

	created() {
		this.carregarFamilia();
		this.consultar();
	},

	methods: {
		formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
			return Formatacao.formatCurrency(value, minimumFractionDigits, maximumFractionDigits);
		},
		formatDate(data) {
			return Formatacao.formatDate(data);
		},
		filtrar() {
			this.consultar();
		},

		clearFilter() {
			const todos = { codFamilia: '*', nomeFamilia: null, codNomemFamilia: 'Todos' };
			this.familiaSelecionada = todos;
			this.consultar();
		},

		carregarFamilia() {
			this.loading = true;
			ProdutoDisponivelService.getFamiliaProdutosDisponivel(this.$props.matricula)
				.then((response) => {
					this.familiaProdutosDisponiveisList = response.data;

					const todos = { codFamilia: '*', nomeFamilia: null, codNomemFamilia: 'Todos' };

					this.familiaProdutosDisponiveisList.unshift(todos);
					this.familiaSelecionada = todos;

				})
				.finally(() => {
					this.loading = false;
				});
		},

		consultar() {
			this.loading = true;
			this.exportarXls = false;

			ProdutoDisponivelService.getProdutosDisponivel(this.$props.matricula, this.familiaSelecionada)
				.then((response) => {
					this.produtosDisponiveisList = response.data;
				})
				.finally(() => {

					if (this.produtosDisponiveisList.length > 0) {
						this.exportarXls = true;
					}

					this.loading = false;
				});
		},

		exportar() {

			const colunas = [
				{ name: "codEstabelecimento", title: "Est" },
				{ name: "codNomeFamilia", title: "Família" },
				{ name: "codItem", title: "Cod Item" },
				{ name: "descricaoItem", title: "Descrição" },
				{ name: "unidade", title: "UN" },
				{ name: "qtdSaldo", title: "QT" },
				{ name: "numeroNotaFiscal", title: "NF" },
				{ name: "dataEmissaoNotaFiscal", title: "Dt NF" },
				{ name: "codNomeImovel", title: "Imóvel" },
				{ name: "valorUnitario", title: "Valor Unitário" },
				{ name: "valorTotal", title: "Valor Total" }
			];


			this.exportarPlanilhaTamanho({
				cols: colunas,
				rows: this.produtosDisponiveisList,
				types: {
					qtdSaldo: 'n',
					valorUnitario: 'n',
					valorTotal: 'n',
					dataEmissaoNotaFiscal: 'd'
				},
				formats: {
					dataEmissaoNotaFiscal: 'dd/MM/yyyy',
					valorUnitario: 'R$ 0.00',
					valorTotal: 'R$ 0.00'
				},
				filename: `produtos-disponiveis.xls`,
				bookType: 'biff8',
				sheetCol: [

					{ "wch": 4.57 }, //Est  
					{ "wch": 31.5 }, //Familia
					{ "wch": 10 }, //CodItem
					{ "wch": 61 }, //Descrição
					{ "wch": 5 }, //Unidade
					{ "wch": 10 }, //Quatidade
					{ "wch": 10 }, //NF
					{ "wch": 10 }, //DataNF
					{ "wch": 35 }, //Imovel
					{ "wch": 15 }, //ValorUnitario
					{ "wch": 15 }, //ValorTotal

				]

			});
		},

		retornarData() {
			let data = new Date();
			let mes = String("0" + (data.getMonth() + 1)).slice(-2);
			let hora = String("0" + data.getHours()).slice(-2);
			let minuto = String("0" + data.getMinutes()).slice(-2);
			let segundo = String("0" + data.getSeconds()).slice(-2);
			return `${data.getFullYear()}${mes}${data.getDate()}${hora}${minuto}${segundo}`;
		},

		exportarPlanilhaTamanho({ rows, cols, filename, map, formats, types, bookType, sheetCol }) {
			var { utils, write } = XLSX,
				{ book_new, encode_cell, book_append_sheet, encode_range } = utils,
				sheet,
				range = ({ s: { c: 0, r: 0 }, e: { c: cols.length, r: rows.length } }),
				book = book_new(),
				type = cols.reduce(
					(acc, { name }) => (acc[name] = types && types[name] || 's') && acc,
					{}
				),
				format = cols.reduce(
					(acc, { name }) => (acc[name] = formats && formats[name] || '@') && acc,
					{}
				);

			//head row
			sheet = cols.reduce(
				(acc, { name, title }, index) => {
					acc[encode_cell({ c: index, r: 0 })] = title ? { t: 's', v: title, z: '@' } : { t: 's', v: name, z: '@' };
					return acc;
				},
				{}
			);
			//data rows
			rows.forEach((row, rowIndex) => {
				cols.forEach(
					({ name }, colIndex) => {
						var key = encode_cell({ c: colIndex, r: rowIndex + 1 }),
							v = type[name] === 'n' ?
								+ row[name]
								: (map ? map(row[name], name, row) : row[name]);
						if (isNaN(v) && type[name] === 'n' || v === null) {
							sheet[key] = {
								t: 's',
								v: '',
								//z:format[name]
							};
						} else {
							sheet[key] = {
								t: type[name],
								v,
								z: format[name]
							};
						}

					}
				);
			});

			sheet['!ref'] = encode_range(range);
			sheet["!cols"] = sheetCol;

			book_append_sheet(book, sheet, 'sheet1');
			this.saveBlob(
				new Blob(
					[this.toArrayBuf(write(book, { bookType: bookType, type: 'binary' }))],
					{ type: 'application/octet-stream' }
				),
				filename
			);
		},

		toArrayBuf(s) {
			var buf = new ArrayBuffer(s.length),
				view = new Uint8Array(buf);
			for (var i = 0; i < s.length; i++) {
				view[i] = s.charCodeAt(i) & 0xFF;
			}
			return buf;
		},

		saveBlob(blob, fileName) {
			if (navigator.msSaveBlob) {
				navigator.msSaveBlob(blob, fileName);
			} else if (window.URL) {
				var url = URL.createObjectURL(blob);
				var anchor = document.createElement('a');
				anchor.href = url;
				anchor.download = fileName;
				var event = document.createEvent('MouseEvents');
				event.initEvent('click', true, true);
				anchor.dispatchEvent(event);
			}
		},

	},
};
</script>

<style>
.p-datatable-wrapper {
	overflow: auto;
}
</style>
